export type Connector = { name: string, title: string, isDC: boolean }

const Type1 = { name: "TYPE-1", title: "Type 1", isDC: false }
const Ccs1 = { name: "CCS-1", title: "CCS 1", isDC: true }
const Type2 = { name: "TYPE-2", title: "Type 2", isDC: false }
const Type2s = { name: "TYPE-2-S", title: "Type 2 Socket", isDC: false }
const Ccs2 = { name: "CCS-2", title: "CCS 2", isDC: true }
const GbtAc = { name: "GBT-AC", title: "GB/T AC", isDC: false }
const GbtDc = { name: "GBT-DC", title: "GB/T DC", isDC: true }
const Chademo = { name: "CHADEMO", title: "CHAdeMO", isDC: true }
const Nacs = { name: "NACS", title: "NACS", isDC: true }
const Shuko = { name: "SHUKO", title: "Shuko", isDC: false }
const Unknown = { name: "UNKNOWN", title: "Unknown type", isDC: false }

export function byName(name: string) {
    switch (name) {
        case 'CCS-1': return Ccs1
        case 'TYPE-1': return Type1
        case 'CCS-2': return Ccs2
        case 'TYPE-2': return Type2
        case 'TYPE-2-S': return Type2s
        case 'GBT-DC': return GbtDc
        case 'GBT-AC': return GbtAc
        case 'CHADEMO': return Chademo
        case 'NACS': return Nacs
        case 'SHUKO': return Shuko
        default: return Unknown
    }
}

export const ALL_CONNECTORS: Connector[] = [
    Ccs2, GbtDc, GbtAc, Type1, Type2, Type2s, Chademo, Ccs1, Nacs, Shuko
];