import { Box, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Checkbox, ListItemText } from "@mui/material";
import { useState } from "react";
import { ALL_CONNECTORS, Connector } from "../model/Connector";

type EvConnectorsDropdownProps = {
    initialSelectedConnectors: string[],
    onConnectorsChange: (connectors: Connector[]) => void;
};

const EvConnectorsDropdown: React.FC<EvConnectorsDropdownProps> = ({ initialSelectedConnectors, onConnectorsChange }) => {
console.log(initialSelectedConnectors);
    const [selectedConnectors, setSelectedConnectors] = useState<string[]>(initialSelectedConnectors);

    const handleConnectorsChange = (event: SelectChangeEvent<unknown[]>) => {
        const newSelectedConnectors = event.target.value as string[];
        setSelectedConnectors(newSelectedConnectors);
    };

    const handleOnClose = () => {
        onConnectorsChange(selectedConnectors.map(c => ALL_CONNECTORS.filter(cc => cc.name === c)[0]));
    };

    return (
        <Box >
            <FormControl fullWidth>
                <InputLabel id="connector-select-label">Тип коннектора</InputLabel>
                <Select
                    labelId="connector-select-label"
                    multiple
                    value={selectedConnectors}
                    onChange={handleConnectorsChange}
                    onClose={handleOnClose}
                    label="Тип коннектора"
                    renderValue={(selected) =>
                        selected.join(', ')
                    }
                >
                    {ALL_CONNECTORS.map((c) => (
                        <MenuItem key={c.name} value={c.name}>
                            <Checkbox checked={
                                selectedConnectors.some(
                                    (selected) => selected === c.name
                                )
                            } />
                            <ListItemText primary={c.title} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default EvConnectorsDropdown;